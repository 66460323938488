<template>
  <div>
    <section class="contact">
      <div class="contact__container">
        <h4 class="contact__title--main">We Are Everywhere</h4>
        <p class="contact__title--sub">Closer to you than ever</p>
        <div class="contact__list">
          <div
            class="contact__list-item"
            :key="index"
            v-for="(contact, index) in contacts"
          >
            <p class="contact__list-item--state">{{ contact.state }}</p>
            <p class="contact__list-item--address">{{ contact.address }}</p>
            <p class="contact__list-item--phone">{{ contact.phone }}</p>
          </div>
        </div>
        <div class="contact__cta">
          <p class="contact__cta--sub">YOU CAN REACH US VIA MAIL</p>
          <p class="contact__cta--main">customercare@lawpavilion.com</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    const contacts = [
      {
        state: "LAGOS ADDRESS",
        address:
          "20 Felicia Koleosho Street, Off Agbaoku Street, Awosika Bus Stop, Opebi, Ikeja, Lagos, Nigeria",
        phone:
          "+234 8050298724, +234 8050298727, +234 8050298540, +234 8050298729",
      },
      {
        state: "Ibadan Office",
        address:
          "Suite C7, 2nd Floor, Choice Plaza, Beside CRAN Pharmacy, ChalLenge, Ibadan, Oyo State.",
        phone: "+234 8050298542, +234 8052496933",
      },
      {
        state: "Abuja Office",
        address: "24, I.T Igbani Street, behind Jabi Upstairs, Jabi,Abuja.",
        phone: "+234 8052496926, +234 8050298726 ",
      },
      {
        state: "Portharcourt Office",
        address:
          "Suite 201,1124Plaza45, Oromenike Street, D/line. Port-Harcourt.",
        phone: "+234 8050298728 ",
      },
      {
        state: "Enugu Office",
        address: "",
        phone: "+234 8052496924",
      },
      {
        state: "Onitsha Office",
        address: "",
        phone: "+234 8130894265",
      },
      {
        state: "Kaduna Office",
        address: "",
        phone: "+234 8036349594",
      },
    ];
    return {
      contacts,
    };
  },
};
</script>

<style lang="scss" scoped>
.contact {
  padding: 9rem 0;
  background: $color-text;
  @include respond(tab-land) {
    padding: 9rem 2rem;
  }
  &__container {
    @include max-container;
  }
  &__title {
    &--main {
      font-weight: 500;
      font-size: 4.2rem;
      line-height: 5rem;
      color: $color-primary-pale-2;
      margin-bottom: 1.5rem;
      text-align: center;

      @include respond(phone) {
        text-align: left;
      }
    }
    &--sub {
      font-size: 2rem;
      line-height: 2.6rem;
      text-align: center;
      color: rgba($color-white, 0.88);
      margin-bottom: 8rem;

      @include respond(phone) {
        text-align: left;
      }
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6.4rem;
    margin-bottom: 5.7rem;

    @include respond(tab-sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4rem;
    }
    @include respond(phone) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__list-item {
    &--state {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.06em;
      color: rgba($color-white, 0.5);
      margin-bottom: 3.2rem;
      text-transform: uppercase;
    }
    &--address {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 136.1%;
      font-feature-settings: "liga" off;
      color: $color-white;
      margin-bottom: 2.4rem;
      max-width: 20.7rem;
    }
    &--phone {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-feature-settings: "liga" off;
      color: rgba($color-grey-light, 0.86);
    }
  }

  &__cta {
    background: linear-gradient(270.68deg, #ffa141 -3.11%, #ff7a00 90.9%);
    border-radius: 1rem;
    padding: 2.3rem 8.2rem;
    width: fit-content;
    margin: 0 auto;
    @include respond(phone) {
      margin: unset;
      padding: 1.5rem 3rem;
    }
    &--sub {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.06em;
      color: $color-white;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    &--main {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: center;
      color: $color-white;
    }
  }
}
</style>
