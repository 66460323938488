<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-youtube/dist/Youtube.min.js";
import "video.js/dist/video-js.css";
import "video.js/dist/video-js.css";
export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {}
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss">
.vjs-big-play-button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(38.9079px) !important;
  width: 2.5em !important;
  height: 2.5em !important;
  border-radius: 50% !important;
  & .vjs-icon-placeholder::before {
    position: relative !important;
    content: url("~@/assets/svg/play-icon.svg") !important;
  }
  &:before {
    content: "";
    display: none;
  }
  &:hover {
    background-color: transparent;
    opacity: 0.7;
  }
}
</style>
