<template>
  <div class="testimonials">
    <div class="header">
      <h4 class="header--main">Our Clients Speak</h4>
      <p class="header--sub">Each one has a unique LawPavilion Story to tell</p>
    </div>
    <div class="speech__container">
      <img
        inline-svg
        src="@/assets/svg/quotes-lg.svg"
        alt=""
        class="lg_quotes"
      />
      <div class="speech__quote">
        <img src="@/assets/svg/quotes-sm.svg" alt="small quotes" />
      </div>
      <div class="speech__quote--mobile">
        <img src="@/assets/svg/quotes-2-sm.svg" alt="small quotes" />
      </div>
      <button class="caret caret--left">
        <img
          inline-svg
          src="@/assets/svg/caret-left-sm.svg"
          alt="caret left icon"
          class="caret-icon--left"
        />
      </button>
      <button class="caret caret--right">
        <img
          inline-svg
          src="@/assets/svg/caret-right-sm.svg"
          alt="caret left icon"
          class="caret-icon--right"
        />
      </button>
      <p class="speech__text" ref="speech">
        {{ testimonials[speechIndex].speech }}
      </p>
    </div>
    <div class="swiper-container mySwiper client__container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(testimonial, index) in testimonials"
          :key="index"
        >
          <div class="client">
            <div class="client__img-container">
              <img
                :src="
                  require(`@/assets/img/memoji/memoji-${(index % 3) + 1}.png`)
                "
                alt="client image"
                class="client__img"
              />
            </div>
            <div class="client__details">
              <p class="client__name">{{ testimonial.name }}</p>
              <p class="client__jd">{{ testimonial.position }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
SwiperCore.use([Navigation, Pagination]);
export default {
  data() {
    const testimonials = [
      {
        name: `Prof. Yemi Osinbajo SAN, GCON`,
        position: "(Vice President of the Federal Republic of Nigeria)",
        speech: `Things have changed dramatically, but I think that what LawPavilion has done is to take this a notch higher with Legal Analytics.`,
      },
      {
        name: "Udechukwu Nnora",
        position: "SAN",
        speech: `When LawPavilion did their demo at its infant stage during an NBA Conference (I can't really remember), many lawyers weren't listening to them. But I saw something good in their products, and so, I bought it. Today, my choice is paying off. I've wowed learned friends with my citation of a plethora of authorities right in court, and most of them who don't use LawPavilion feel intimidated each time I have a matter with them in court. Some say, ‘Your head is filled with books,' but I tell them, ‘No. My head isn't filled with books; LawPavilion is the secret'.`,
      },
      {
        name: "Otunba Otayemi",
        position: "",
        speech:
          "It has made quintessential legal practice so effortless for me that I have become a self appointed ambassador of the LawPavilion brand.",
      },
      {
        name: "Godwin Omoaka",
        position: "SAN, FCIArb",
        speech: `I must commend the LawPavilion's team for facilitating a "go to" legal resource platform that continues to contribute and facilitate the development of law practice and Law in Nigeria.`,
      },
      {
        name: "Prof. Henry Alisigwe",
        position: "",
        speech: `If your company could send their staff to go to another state to resolve a client's issue, and the staff actually did, that shows you people keep to your words. I'm impressed.`,
      },
      {
        name: "Gobari Inyorne",
        position: "",
        speech: `I thank the LawPavilion team for making legal research as seamless as it is. I have heard about LawPavilion on Whatsapp from one of your newsletters but today I have seen how effortless is it to research favourite topics. Once again, thank you.`,
      },
      {
        name: "O. J. Onoja, SAN",
        position: "",
        speech: `LawPavilion is Number 1. LawPavilion is a seed of knowledge. I want  to reiterate it that LawPavilion is the number 1 that every competition is trying to catch up with. It enhances speedy delivery of judgments, efficiency and quality. It's so amazing.`,
      },
      {
        name: "Edward Ashiekaa, SAN",
        position: "",
        speech: `Unrivalled. I tried other softwares but found LawPavilion unrivalled and satisfactorily met my legal research needs. Ease of access to latest decisions of superior courts in electronic reporting is quite commendable. LawPavilion research software boosted my efficiency and productivity, made legal research less burdensome and has enhanced the quality of my briefs and presentation`,
      },
      {
        name: "Godwin Omoaka, SAN, FCIArb",
        position: "",
        speech: `I must commend the LawPavilion's team for facilitating a "go to" legal resource platform that continues to contribute and facilitate the development of law practice and Law in Nigeria".`,
      },
      {
        name: "Anthony I. Ani, SAN",
        position: "",
        speech: `Trailblazing. LawPavilion has actually revolutionized electronic law reporting and greatly assisted in legal research and advancement of law and justice in Nigeria`,
      },
      {
        name: "Dafe Akpedeye, SAN",
        position: "",
        speech: `'Increased Delivery. Today, I can be in court and have access to all superior court judgments, which may be handy in the matter in issue by just a snap of the hand. LawPavilion has improved our knowledge of the law and helped save both energy and man hours that ordinarily would be spent digging the library for on authority or the other.`,
      },
      {
        name: `Babajide Koku, SAN`,
        position: "",
        speech: `Do More with Less Stress. With LawPavilion, I am able to attend to more files stress free`,
      },
      {
        name: "Daniel Ajayi",
        position: "",
        speech: `Omo! LawPavilion is a life saver oo. I like the fact that it's not just cases it has, but also statutes that can be accessed through it. I love that feature most about it because finding the hardcopy of those statutes can be really difficult, but the soft copy just saves my life always. There are times during researching, I put in key words and I get my results so fast. Really efficient.`,
      },
      {
        name: "Hon Justice Mohammed",
        position: "Niger state Judiciary",
        speech: `I'm really enjoying the LawPavilion more than when i was at the private bar. The LawPavilion guys are doing a great job for the Development of Nigerian legal system.`,
      },
    ];
    return {
      swiper: undefined,
      testimonials,
      speechIndex: 0,
    };
  },
  mounted() {
    this.swiper = new Swiper(".swiper-container", {
      // Optional parameters
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1000,
      breakpoints: {
        // when window width is >= 480px
        580: {
          slidesPerView: 3,
        },
        // when window width is >= 640px
        700: {
          slidesPerView: 5,
        },
      },
      slideToClickedSlide: true,
      pagination: {
        el: ".swiper-pagination",
      },

      // Navigation arrows
      navigation: {
        nextEl: ".caret--right",
        prevEl: ".caret--left",
      },
    });
    this.speechIndex = this.swiper.realIndex;
    this.swiper.on(
      "slideChange",
      function () {
        this.speechIndex = this.swiper.realIndex;
      }.bind(this)
    );
    this.swiper.on(
      "slideNextTransitionStart",
      function () {
        this.addSlideInRight();
      }.bind(this)
    );
    this.swiper.on(
      "slidePrevTransitionStart",
      function () {
        this.addSlideInLeft();
      }.bind(this)
    );
    this.swiper.on(
      "slideNextTransitionEnd",
      function () {
        this.removeSlideInRight();
      }.bind(this)
    );
    this.swiper.on(
      "slidePrevTransitionEnd",
      function () {
        this.removeSlideInLeft();
      }.bind(this)
    );
  },
  methods: {
    onSwiper() {},
    onSlideChange() {},
    addSlideInRight() {
      this.$refs.speech.classList.add("slideInRight");
    },
    removeSlideInRight() {
      this.$refs.speech.classList.remove("slideInRight");
    },
    addSlideInLeft() {
      this.$refs.speech.classList.add("slideInLeft");
    },
    removeSlideInLeft() {
      this.$refs.speech.classList.remove("slideInLeft");
    },
  },
};
</script>

<style lang="scss" scoped>
.testimonials {
  background: $color-primary-pale;
  padding: 10rem 0;

  .header {
    margin-bottom: 10rem;
    @include respond(phone) {
      margin-bottom: 4rem;
    }
    &--main {
      font-weight: 600;
      font-size: 4.2rem;
      line-height: 5rem;
      margin-bottom: 2.2rem;
      text-align: center;
      @include respond(phone) {
        padding: 0 2rem;
        text-align: left;
        font-size: 5rem;
        max-width: 11ch;
      }
    }
    &--sub {
      font-size: 2rem;
      line-height: 2.4rem;
      text-align: center;
      @include respond(phone) {
        text-align: left;
        padding: 0 2rem;
        font-size: 2.4rem;
        color: $color-brown-1;
      }
    }
  }

  %centerTestimonials {
    max-width: 85.4rem;
    width: 80%;
    margin: 0 auto;
  }
  .speech {
    &__container {
      background: $color-white;
      border-radius: 1rem;
      @extend %centerTestimonials;
      display: flex;
      padding: 4.7rem 4rem;
      position: relative;
      margin-bottom: 3.7rem;
      min-height: 30.4rem;
      @include respond(phone) {
        flex-direction: column;
        width: 65%;
      }
      &::after {
        content: "";
        border: 1.7rem solid transparent;
        border-top-color: $color-white;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
      }
      .lg_quotes {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(120%, -100%);
        opacity: 0.3;
        @include respond(tab-land) {
          display: none;
        }
      }
      .caret {
        border: unset;
        outline: unset;
        padding: 1.2rem 1.6rem;
        background: $color-white;
        box-shadow: 0px 2rem 4rem rgba(255, 161, 65, 0.08);
        border-radius: 1rem;
        position: absolute;

        &--left {
          top: 50%;
          left: -3.2rem;
          transform: translate(-100%, -50%);
          @include respond(phone) {
            left: -2rem;
          }
        }
        &--right {
          top: 50%;
          right: -3.2rem;
          transform: translate(100%, -50%);
          @include respond(phone) {
            right: -2rem;
          }
        }
      }
    }
    &__quote {
      margin-right: 2rem;
      &--mobile {
        display: none;
        @include respond(phone) {
          display: block;
          margin-bottom: 2rem;
        }
      }
      @include respond(phone) {
        margin-right: unset;
        display: none;
      }
    }
    &__text {
      font-size: 1.9rem;
      line-height: 3rem;
      color: $color-text-2;
      font-weight: 500;
    }
  }
  .client {
    display: flex;
    align-items: center;
    & > :not(:last-child) {
      margin-right: 1.6rem;
    }
    &__container {
      display: flex;
      @extend %centerTestimonials;
    }
    &__img-container {
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-white;
    }
    &__img {
      width: 4.6rem;
      height: 4.6rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }
    &__details {
      transition: opacity 50ms 200ms linear;
      opacity: 0;
      display: none;
      width: 15rem;
      max-width: 20ch;
    }
    &__name {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: $color-text-2;
      text-align: left;
      /* @include truncate-text(1); */
    }
    &__jd {
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: $color-text-2;
      text-align: left;
      @include truncate-text(1);
    }
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms linear;
  min-width: fit-content !important;
  & > :not(:last-child) {
    margin-right: 8rem;
  }
  &.swiper-slide-active {
    .client__details {
      opacity: 1;
      display: block;
    }
  }
}

.swiper-container {
  width: 100%;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}
.slideInRight {
  animation-name: slideInRight;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
}
.slideInLeft {
  animation-name: slideInLeft;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
}
@keyframes slideInRight {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
