<template>
  <div>
    <section class="count">
      <div class="count__container">
        <h4 class="count__main-title">Numbers that matter</h4>
        <p class="count__sub-title">
          We do the work that makes impact everyday
        </p>
        <div class="count__list">
          <div class="count__list-item">
            <p class="count__number">14</p>
            <p class="count__desc">+ More Products</p>
          </div>
          <div class="count__list-item">
            <p class="count__number">13,726</p>
            <p class="count__desc">Fulfilled clients and counting</p>
          </div>
          <div class="count__list-item">
            <p class="count__number">172</p>
            <p class="count__desc">Dedicated Staffs</p>
          </div>
          <div class="count__list-item">
            <p class="count__number">16</p>
            <p class="count__desc">Years of Pioneering</p>
          </div>
        </div>
        <div class="stripe-1"></div>
        <div class="stripe-2"></div>
        <div class="stripe-3"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Count",
};
</script>

<style lang="scss" scoped>
.count {
  padding: 9rem 0;
  background: $color-text;
  @include respond(tab-land) {
    padding: 9rem 2rem;
  }
  %mainText {
    font-weight: bold;
    font-size: 4.8rem;
    line-height: 6.4rem;
    text-align: center;
    font-feature-settings: "liga" off;
    color: #ffffff;
  }
  %subText {
    font-size: 1.8rem;
    line-height: 3.2rem;
    text-align: center;
    font-feature-settings: "liga" off;
    color: #ffffff;
  }

  &__container {
    @include max-container;
    background: linear-gradient(270.68deg, #ffa141 -3.11%, #ff7a00 90.9%);
    border-radius: 3rem;
    position: relative;
    padding: 6.4rem;
    overflow: hidden;
  }

  &__main-title {
    @extend %mainText;
    margin-bottom: 0.8rem;
  }
  &__sub-title {
    @extend %subText;
    margin-bottom: 4.8rem;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-row-gap: 6rem;
    }
  }
  &__list-item {
    width: 100%;
    &:not(:last-child) {
      border-right: 1px solid rgba($color-white, 0.4);
      @include respond(phone) {
        border-right: unset;
        border-bottom: 1px solid rgba($color-white, 0.4);
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__number {
    @extend %mainText;
  }
  &__desc {
    @extend %subText;
  }
  .stripe-1 {
    position: absolute;
    width: 69.5rem;
    height: 38.7rem;
    background: linear-gradient(77.62deg, #ffa141 36.51%, #ff7a00 96.4%);
    mix-blend-mode: screen;
    opacity: 0.26;
    transform: matrix(1, -1, 0, 0.82, 0, 0);
    bottom: -106%;
    right: 14%;
    z-index: 3;
  }
  .stripe-2 {
    position: absolute;
    width: 78.9rem;
    height: 19.6rem;
    background: linear-gradient(77.43deg, #ffa141 3.74%, #ff7a00 55.37%);
    mix-blend-mode: multiply;
    opacity: 0.16;
    transform: matrix(1, -1, 0, 0.82, 0, 0) scale(1.4);
    top: -92%;
    right: -64%;
    z-index: 2;
  }
  .stripe-3 {
    position: absolute;
    width: 119.2rem;
    height: 30.3rem;
    background: linear-gradient(77.62deg, #ffa141 38.4%, #ff7a00 54.58%);
    mix-blend-mode: multiply;
    opacity: 0.16;
    transform: matrix(1, -1, 0, 0.82, 0, 0);
    right: -32%;
    z-index: 1;
  }
}
</style>
