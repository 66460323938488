<template>
  <section class="values">
    <div class="values__container">
      <div class="first-row">
        <div
          class="circle"
          :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
          data-text="Passion"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 10h6l-3-4-3 4zm0 4h6l-3 4-3-4zM1 0h6v24H1a1 1 0 01-1-1V1a1 1 0 011-1z" fill="#FF7B01"/><path d="M9 0h6a1 1 0 011 1v22a1 1 0 01-1 1H9V0z" fill="#FFA141"/></svg>
          <svg width="165" height="31" viewBox="0 0 165 31" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'circle__wave'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 25.464s48.678 7.638 83.877 1.429c35.2-6.21 78.331-25.05 78.331-25.05" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 6"/></svg>
        </div>
        <div
          :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
          class="circle"
          data-text="Innovation"
        >
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22 5.17v-4a1 1 0 00-1-1H1a1 1 0 00-1 1v4h22z" fill="#FF7A00"/><path d="M0 7.17v14a1 1 0 001 1h5v-15H0z" fill="#FFA141"/><path d="M8 22.17h13a1 1 0 001-1v-14H8v15z" fill="#FF7A00"/></svg>
          <svg width="165" height="28" viewBox="0 0 165 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'circle__wave'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 2.809s48.752-4.635 84.004 1.564c35.253 6.199 78.45 22.016 78.45 22.016" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 6"/></svg>
        </div>
        <div
          class="circle"
          :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
          data-text="Customer Satisfaction"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 10h6l-3-4-3 4zm0 4h6l-3 4-3-4zM1 0h6v24H1a1 1 0 01-1-1V1a1 1 0 011-1z" fill="#FF7B01"/><path d="M9 0h6a1 1 0 011 1v22a1 1 0 01-1 1H9V0z" fill="#FFA141"/></svg>
          <svg width="165" height="31" viewBox="0 0 165 31" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'circle__wave'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 25.464s48.678 7.638 83.877 1.429c35.2-6.21 78.331-25.05 78.331-25.05" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 6"/></svg>
        </div>
        <div
          class="circle"
          :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
          data-text="Quality"
        >
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22 5.17v-4a1 1 0 00-1-1H1a1 1 0 00-1 1v4h22z" fill="#FF7A00"/><path d="M0 7.17v14a1 1 0 001 1h5v-15H0z" fill="#FFA141"/><path d="M8 22.17h13a1 1 0 001-1v-14H8v15z" fill="#FF7A00"/></svg>
        </div>
      </div>
      <div class="first-row">
        <div
          class="circle"
          :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
          data-text="Integrity"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'dot_lines'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 10h6l-3-4-3 4zm0 4h6l-3 4-3-4zM1 0h6v24H1a1 1 0 01-1-1V1a1 1 0 011-1z" fill="#FF7B01"/><path d="M9 0h6a1 1 0 011 1v22a1 1 0 01-1 1H9V0z" fill="#FFA141"/></svg>
          <svg width="165" height="31" viewBox="0 0 165 31" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'circle__wave'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 25.464s48.678 7.638 83.877 1.429c35.2-6.21 78.331-25.05 78.331-25.05" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 6"/></svg>
        </div>
        <div
          class="circle"
          :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
          data-text="Timeliness"
        >
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22 5.17v-4a1 1 0 00-1-1H1a1 1 0 00-1 1v4h22z" fill="#FF7A00"/><path d="M0 7.17v14a1 1 0 001 1h5v-15H0z" fill="#FFA141"/><path d="M8 22.17h13a1 1 0 001-1v-14H8v15z" fill="#FF7A00"/></svg>
          <svg width="165" height="28" viewBox="0 0 165 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'circle__wave'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 2.809s48.752-4.635 84.004 1.564c35.253 6.199 78.45 22.016 78.45 22.016" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 6"/></svg>
        </div>
        <div
          class="circle"
          :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
          data-text="Team"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 10h6l-3-4-3 4zm0 4h6l-3 4-3-4zM1 0h6v24H1a1 1 0 01-1-1V1a1 1 0 011-1z" fill="#FF7B01"/><path d="M9 0h6a1 1 0 011 1v22a1 1 0 01-1 1H9V0z" fill="#FFA141"/></svg>
        </div>
      </div>
    </div>
    <div class="values__container values__container--mobile">
      <div class="container">
        <div
          class="circle__container"
          v-for="(value, index) in values"
          :key="index"
        >
          <div
            class="circle"
            :class="theme === 'light' ? 'circle__light' : 'circle__dark'"
            :data-text="value"
          >
            <img
              svg-inline
              :src="require(`@/assets/svg/stands-icon-${(index % 2) + 1}.svg`)"
              alt="icon"
            />
          </div>
          <p
            :class="theme === 'light' ? 'text text__dark' : 'text text__light'"
          >
            {{ value }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "circle__dark",
    },
  },
  data() {
    const values = [
      "Passion",
      "Innovation",
      "Customer Satisfaction",
      "Quality",
      "Integrity",
      "Timeliness",
      "Team",
    ];
    return {
      values,
    };
  },
  name: "Values",
};
</script>

<style lang="scss" scoped>
.values {
  &__container {
    @include max-container;
    @include respond(phone) {
      display: none;
    }
    &--mobile {
      display: none;
      $gap: 4.5rem;
      .container {
        display: flex;
        gap: $gap;
        padding: unset;
        flex-wrap: wrap;
        & > * {
          width: calc((100% - #{$gap}) / 2);
        }
      }
      @include respond(phone) {
        display: flex;
        gap: 3rem;
      }
      .circle__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .first-row {
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 12.4rem;
    }
    & > :not(:last-child) {
      margin-right: 17.5rem;
      @include respond(tab-sm) {
        margin-right: 9rem;
      }
    }
  }
  .circle {
    width: 7.2rem;
    min-width: 7.2rem;
    height: 7.2rem;
    @include flex-center;
    border-radius: 50%;
    position: relative;

    &::after {
      content: attr(data-text);
      position: absolute;
      top: calc(100% + 1.6rem);
      width: max-content;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.8rem;
      font-feature-settings: "liga" off;
      @include respond(phone) {
        content: unset;
      }
    }
    &__wave {
      position: absolute;
      left: 110%;
      top: 50%;
      transform: translateY(-50%);
      @include respond(tab-sm) {
        display: none;
      }
    }
  }
  .circle__dark {
    background: $background-grey;
    &::after {
      color: #ffffff;
    }
  }
  .circle__light {
    background: $color-primary-pale;

    .circle__wave {
      path {
        stroke: $color-primary-2;
      }
    }

    &::after {
      color: $color-text;
    }
  }
  .text {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.8rem;
    text-align: center;
    &__light {
      color: $color-white;
    }
    &__dark {
      color: $color-text;
    }
  }
}
</style>
