<template>
  <div>
    <div class="trustees">
      <h4 class="trustees__header">
        Trusted by Thousands of Clients Across Africa
      </h4>
      <ul class="trustees__list">
        <li class="trustees__list-item">
          <img
            src="@/assets/svg/trustees/trustee-logo-1.png"
            alt="trustee logo 1"
          />
        </li>
        <li class="trustees__list-item">
          <img
            src="@/assets/svg/trustees/trustee-logo-2.png"
            alt="trustee logo 1"
          />
        </li>
        <li class="trustees__list-item">
          <img
            src="@/assets/svg/trustees/trustee-logo-3.png"
            alt="trustee logo 1"
          />
        </li>
        <li class="trustees__list-item">
          <img
            src="@/assets/svg/trustees/trustee-logo-4.png"
            alt="trustee logo 1"
          />
        </li>
        <li class="trustees__list-item">
          <img
            src="@/assets/svg/trustees/trustee-logo-5.png"
            alt="trustee logo 1"
          />
        </li>
        <li class="trustees__list-item">
          <img
            src="@/assets/svg/trustees/trustee-logo-6.png"
            alt="trustee logo 1"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Trustees",
};
</script>

<style lang="scss" scoped>
.trustees {
  padding: 4rem 0;
  padding-bottom: 14rem;
  @include respond(tab-land) {
    padding: 8rem 0;
  }
  &__header {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
    margin-bottom: 3.4rem;
  }

  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 1px;
    border-image-source: linear-gradient(
      90deg,
      rgba(221, 175, 132, 0.1) 0%,
      rgba(221, 175, 132, 0.6) 50.38%,
      rgba(221, 175, 132, 0.1) 100%
    );
    & > :not(:last-child) {
      margin-right: 6rem;
    }
    overflow: auto;
    &__item {
    }
  }
}
</style>
