<template>
  <div>
    <div class="details-container">
      <div class="left-column">
        <div class="left-container">
          <router-link :to="{ name: 'careers' }" class="navigation">
            <img
              src="@/assets/svg/go-back-icon.svg"
              alt="go back icon"
              class="back"
            />
            <span>Back to Jobs</span>
          </router-link>
          <p class="job__title">UX Engineering Manager, Product Design</p>
          <p class="job__type">(Remote)</p>
          <div class="footer">
            <button class="btn btn--primary" @click="apply">Apply Now</button>
            <div class="shareable-link">
              <img src="@/assets/svg/link-icon.svg" alt="link icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="right-container">
          <div class="about">
            <div class="header">About Position:</div>
            <p class="text">
              {{ data.about }}
            </p>
          </div>
          <div class="bits">
            <div
              class="bits__item"
              v-for="(bit, index) of data.jds"
              :key="index"
            >
              <p class="bits__item--header">{{ bit.name }}</p>
              <p class="bits__item--desc">{{ bit.description }}</p>
            </div>
          </div>
          <section class="section">
            <p class="section__title">What You'll Do:</p>
            <ul class="ulist">
              <li
                class="ulist__item"
                v-for="(jd, index) of data.jds"
                :key="index"
              >
                {{ jd }}
              </li>
            </ul>
          </section>
          <section class="section">
            <p class="section__title">Requirement:</p>
            <ul class="ulist">
              <li
                class="ulist__item"
                v-for="(requirement, index) of data.requirements"
                :key="index"
              >
                {{ requirement }}
              </li>
            </ul>
          </section>
          <section class="section">
            <p class="section__title">
              Application Instructions (Read carefully)
            </p>
            <ol class="list">
              <li
                class="list__item"
                v-for="(instruction, index) of data.instructions"
                :key="index"
              >
                {{ instruction }}
              </li>
            </ol>
          </section>
          <section class="section">
            <p class="section__title">Other Openings</p>
            <div class="openings">
              <div
                class="openings__item"
                v-for="(opening, index) in data.openings"
                :key="index"
              >
                <div class="header">
                  <p class="location">{{ opening.location }}</p>
                  <p class="job-type">{{ opening.job_type }}</p>
                </div>
                <div class="body">
                  <p class="job-title">{{ opening.title }}</p>
                  <div
                    class="pills"
                    v-for="(tab, index) in opening.tabs"
                    :key="index"
                  >
                    <div class="pills__item">{{ tab }}</div>
                  </div>
                </div>
              </div>
              <button class="btn btn--black">See all job openings</button>
            </div>
          </section>
        </div>
      </div>
    </div>
    <Contact />
  </div>
</template>

<script>
import Contact from "@/components/Contact";
export default {
  name: "CareerDetails",
  components: { Contact },
  data() {
    const data = {
      about:
        "We’re looking for a passionate sales operations manager to join the Marketplace organization – a cross-functional group dedicated to helping artists, podcasters, and their teams thrive. In this role you’ll help to develop and maintain the systems and processes needed for us to run our growing business offering tools to artists, labels, and music marketers. You’ll work directly with a cross-functional group across sales, marketing, R&D, and business teams. This is a high-growth area of Spotify, and ultimately you’ll be responsible for the healthy performance of a new and critical function.",
      bits: [
        {
          name: "Location",
          description: "Remote",
        },
        {
          name: "Job type",
          description: "Permanent/Contract",
        },
      ],
      jds: [
        "You will structure and negotiate content development, production, licensing, partnership, talent, and similar types of deals with a focus on live audio content, among others.",
        "You will collaborate with various Spotify content and business teams on content strategy, financial analyses, product, initiatives, and other experiences crafted to evolve and grow Spotify’s content offerings.",
        "You will maintain awareness of recent developments in the law, technology, and industry as they relate to our live and content businesses.",
      ],
      requirements: [
        "You have received your BS/BA (JD is a plus) and have 5+ years of content/digital media/entertainment industry business affairs experience (particularly with respect to live/interactive platforms/formats), structuring and negotiating high-value talent, production, and licensing deals.",
        "You are highly self-motivated and thrive in high-growth, high-paced, complex, and innovative business environments.",
        "You have experience collaborating closely with rights holders/service providers (including creators, production companies, and other talent) and their representatives (talent agencies, lawyers, and managers), with focus in the live audio/video space.",
        "You have experience collaborating closely with rights holders/service providers (including creators, production companies, and other talent) and their representatives (talent agencies, lawyers, and managers), with focus in the live audio/video space.",
      ],
      instructions: [
        "Click the Apply Now button to go to the application form, and answer the application question.",
        "Application deadline is 11:59 pm WAT on Sunday, April 25th.",
        "In your cover letter, please take care to explain how your professional and personal experiences have set you up to thrive in this role. This is important. We find that while unsuccessful applicants tend to simply summarize their CV, successful applicants are able to clearly articulate how their background makes them uniquely suited for this role.",
      ],
      openings: [
        {
          location: "Ibadan",
          job_type: "Permanent",
          title: "Legal Counsel - Platform Liability",
          tabs: ["Data Entry"],
        },
        {
          location: "Lagos",
          job_type: "Contract",
          title: "Manager Business Affairs - Studio Content/Live Audio",
          tabs: ["Data Entry"],
        },
        {
          location: "Abuja",
          job_type: "Permanent",
          title: "Customer Satisfaction Officer",
          tabs: ["Users Satisfaction"],
        },
      ],
    };
    return {
      data,
    };
  },
  methods: {
    apply() {
      this.$router.push({ name: "apply", params: { id: 1 } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./careerDetails.component.scss";
</style>
