<template>
  <div>
    <section class="stands-for">
      <div class="stands-for__container">
        <h4 class="title">What LawPavilion Stands For</h4>
        <Values />
      </div>
    </section>
  </div>
</template>

<script>
import Values from "@/components/Values";
export default {
  name: "StandsFor",
  components: {
    Values,
  },
};
</script>

<style lang="scss" scoped>
.stands-for {
  background: $color-text;
  padding: 12rem;
  @include respond(phone) {
    padding: 8rem 2rem;
  }
  &__container {
    @include max-container;
  }

  .title {
    text-align: center;
    font-weight: 500;
    font-size: 4.2rem;
    line-height: 5rem;
    font-feature-settings: "liga" off;
    color: $color-primary-pale-2;
    margin-bottom: 7.7rem;
    @include respond(phone) {
      text-align: left;
      max-width: 16ch;
    }
  }
}
</style>
