<template>
  <div class="">
    <loader v-show="isLoading" />
    <div>
      <Hero :isLoading="isLoading" />
      <Trustees />
      <Tools />
      <Brief />
      <Testimonials />
      <stands-for />
      <Count />
      <Contact />
    </div>
    <b-modal id="my-modal">
      <img
        src="@/assets/img/landing-page/stopwatch.png"
        alt="stopwatch image"
        class="img"
      />
      <h4 class="heading-4">Don’t Be Left Out</h4>
      <p class="paragraph">
        Activate your NBA-LawPavilion Professional Leverage Package now before
        it closes!
      </p>
      <div class="btn-container">
        <a
          class="btn btn--outline"
          target="__blank"
          href="https://bit.ly/3lvnxbY"
          >Learn more</a
        >
        <a
          class="btn btn--primary"
          target="__blank"
          href="https://bit.ly/3ASW8Xj"
          >Activate now</a
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "./Hero";
import Trustees from "./Trustees";
import Tools from "./Tools";
import Brief from "./Brief";
import Testimonials from "./Testimonials";
import StandsFor from "./StandsFor";
import Count from "./Count";
import Contact from "@/components/Contact";
import Loader from "@/components/Loader";

export default {
  name: "Index",
  components: {
    Hero,
    Trustees,
    Tools,
    Brief,
    Testimonials,
    StandsFor,
    Count,
    Contact,
    Loader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    checkStateComplete() {
      if (document.readyState == "complete") {
        this.isLoading = false;
        this.$bvModal.show("my-modal");
      }
    },
  },
  mounted() {
    this.checkStateComplete();
    document.onreadystatechange = () => {
      this.checkStateComplete();
    };
  },
};
</script>

<style lang="scss" scoped>
@include waveKeyFrame;
/deep/ .modal-header {
  border-bottom: unset;

  .close {
    font-size: 2.5rem;
  }
}
/deep/ .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/deep/ .modal-content {
  border-radius: 1rem;
}

.modal-body {
  .img {
    margin: 0 auto;
    display: block;
    width: 11.4rem;
    margin-bottom: 2.1rem;
  }
  .heading-4 {
    font-style: normal;
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 5.8rem;
    letter-spacing: -0.03em;
    color: #0c0c0c;
    margin-bottom: 1rem;
    text-align: center;
  }
  .paragraph {
    margin: 0 auto;
    max-width: 80%;
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: center;
    color: #555555;
    margin-bottom: 3.4rem;
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.3rem;
    & > :not(:last-child) {
      margin-right: 1.6rem;
    }

    .btn--outline {
      border: 1px solid #0c0c0c;
    }
  }
}
/deep/ .modal-footer {
  display: none;
}
</style>
