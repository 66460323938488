<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "WebLayout",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
