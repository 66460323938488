import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/website-views/LandingPage/Index.vue";
// import About from "@/views/website-views/about/About.component.vue";
// import Career from "@/views/website-views/career/Career.component.vue";
// import Apply from "@/views/website-views/apply/apply.component.vue";
import CareerDetails from "@/views/website-views/careerDetails/careerDetails.component.vue";
// import LawRights from "@/views/law-rights/LawRights.vue";
// import PrimeHome from "@/views/prime/prime.component.vue";
import WebLayout from "@/views/WebLayout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "webLayout",
    component: WebLayout,
    children: [
      {
        path: "",
        component: Index,
      },
      {
        path: "about",
        component: () =>
          import(
            /*webpackChunkName: "about"*/ "../views/website-views/about/About.component.vue"
          ),
      },
      {
        name: "careers",
        path: "careers",
        component: () =>
          import(
            /*webpackChunkName: "careers"*/ "../views/website-views/career/Career.component.vue"
          ),
        children: [
          {
            name: "career-details",
            path: "/:id",
            component: CareerDetails,
          },
        ],
      },
      // {
      //   name: "career-details",
      //   path: "careers/:id",
      //   component: CareerDetails,
      // },
      {
        name: "apply",
        path: "careers/:id/apply",
        component: () =>
          import(
            /*webpackChunkName: "apply"*/ "../views/website-views/apply/apply.component.vue"
          ),
      },
    ],
  },
  {
    path: "/law-rights",
    name: "lawRights",
    component: () =>
      import(
        /*webpackChunkName: "lawRights"*/ "../views/law-rights/LawRights.vue"
      ),
  },
  {
    path: "/prime",
    name: "PrimeHome",
    component: () =>
      import(
        /*webpackChunkName: "prime"*/ "../views/prime/prime.component.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
