<template>
  <div>
    <section class="brief">
      <div class="brief__video">
        <video-player :options="videoOptions" />
      </div>
      <div class="brief__content">
        <div class="first-col">
          Accelerating Access to Justice through Innovative and Emerging
          Technologies
        </div>
        <div class="second-col">
          <div class="accordion" role="tablist">
            <div class="collapse-card">
              <div
                class="collapse-card__header"
                block
                v-b-toggle.accordion-1
                variant="info"
              >
                <img src="@/assets/svg/brief-icon-1.svg" alt="" />
                <p class="title">
                  {{ briefs[0].title }}
                </p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 9l-5 5-5-5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <b-collapse
                id="accordion-1"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="collapse-card__body">
                  <p>
                    {{ briefs[0].desc }}
                  </p>
                </div>
              </b-collapse>
            </div>
            <div class="collapse-card">
              <div
                class="collapse-card__header"
                block
                v-b-toggle.accordion-2
                variant="info"
              >
                <img src="@/assets/svg/brief-icon-2.svg" alt="" />
                <p class="title">
                  {{ briefs[1].title }}
                </p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 9l-5 5-5-5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="collapse-card__body">
                  <p>
                    {{ briefs[1].desc }}
                  </p>
                </div>
              </b-collapse>
            </div>
            <div class="collapse-card">
              <div
                class="collapse-card__header"
                block
                v-b-toggle.accordion-3
                variant="info"
              >
                <img src="@/assets/svg/brief-icon-3.svg" alt="" />
                <p class="title">
                  {{ briefs[2].title }}
                </p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 9l-5 5-5-5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="collapse-card__body">
                  <p>
                    {{ briefs[2].desc }}
                  </p>
                </div>
              </b-collapse>
            </div>
            <div class="collapse-card">
              <div
                class="collapse-card__header"
                block
                v-b-toggle.accordion-4
                variant="info"
              >
                <img src="@/assets/svg/brief-icon-4.svg" alt="" />
                <p class="title">
                  {{ briefs[3].title }}
                </p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 9l-5 5-5-5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <b-collapse
                id="accordion-4"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="collapse-card__body">
                  <p>
                    {{ briefs[3].desc }}
                  </p>
                </div>
              </b-collapse>
            </div>
            <div class="collapse-card">
              <div
                class="collapse-card__header"
                block
                v-b-toggle.accordion-5
                variant="info"
              >
                <img src="@/assets/svg/brief-icon-4.svg" alt="" />
                <p class="title">
                  {{ briefs[4].title }}
                </p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 9l-5 5-5-5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <b-collapse
                id="accordion-5"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="collapse-card__body">
                  <p>
                    {{ briefs[4].desc }}
                  </p>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "Brief",
  components: {
    VideoPlayer,
  },
  data() {
    const briefs = [
      {
        title: `Lightening fast SmartSearch + Multiple E-Book Reader for superb legal research experience`,
        desc: `Smartsearch is your personalized inbuilt search engine for smart users, we’ve replaced the traditional way of delivering search results with an intelligent algorithm that allows you to get precise, accurate and faster results. In other words, smartsearch makes you smarter. Moreover, your reading experience is now smoother with a Multiple E-book Reader that keeps everything you need in one place and is friendly to the eyes; lighter than books; easier to flip from page to page, giving you more than the basic function of an e-book reader. Open multiple reading tabs, bookmark, highlight, comment, and take notes; for a personalised research experience tha is unparalled.`,
      },
      {
        title: `Robust Mobile E-Library of Textbooks, Journals, Articles, Case Law and more`,
        desc: `A robust e-library when put to regular use, is larger than the traditional law library, even in a Senior Advocate’s office. Our mobile, easy to use and up-to-date E-library houses LawPavilion Electronic Law Report (LPELR), Textbooks and Journals, Articles, Law Reports, Laws, Forms, Agreement Templates, Civil Procedure Rules and every legal resource you need; enhancing the quality of your work. We often say, and so true, if you don't find it on LawPavilion, you'll likely not find it anywhere else.`,
      },
      {
        title: `Artificial Intelligence in everyday legal practice`,
        desc: `We are your vehicular intelligence on the road to the AI revolution: you are the comfortable passenger, Legal Analytics is the driver, LawPavilion is your vehicle. Using Artificial Intelligence, we offer you the length and breadth of any legal issue so that you can have the power of predictive insight and win on all your briefs. `,
      },
      {
        title: `Case Management tools that bring you into the future of legal practice today`,
        desc: `Keywords best describes the benefits, features, and functionality of our Case Management System, namely: integrated organization, optimized efficiency, untold flexibility, favourable case outcomes, for enduring profitability. Our Case Manager is the future law firm operating in the now, practicing law at the speed of thought, while enjoying low entry cost and speedy setup.  Work from anywhere with prompt availability, uninterrupted access, through seamless collaboration across your internal teams. Security? You’ve got a robust, fool-proof data back-up and recovery system put in place.
`,
      },
      {
        title: `DIY resources with Insight Notes - editable Forms and Agreements Templates`,
        desc: `We’ve done a lot for you, however, we left some areas you are likely to enjoy doing and expertly creating yourself. Forms and Agreements are part of the toolkits in your digital toolbox. They’re interactive templates, editable, annotated, and standardized for solicitors and arbitrators, including AfCFTA Arbitration Protocols and a compendium of arbitration rules, effectively guaranteeing speedy resolution of all solicitor and arbitrator related issues. The law is dynamic, your processes and resource materials are, too, and that's why we made them editable.  `,
      },
    ];
    return {
      briefs,
      videoOptions: {
        autoplay: false,
        fluid: true,
        controls: true,
        techOrder: ["youtube"],
        sources: [
          {
            src: "https://www.youtube.com/watch?v=tAemDNUdFsQ",
            type: "video/youtube",
            youtube: { iv_load_policy: 1 },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.brief {
  background: $color-text;
  padding: 2rem;

  &__video {
    width: 100%;
    border-radius: 1.5rem;
    margin-bottom: 8rem;
    @include max-container;
    margin-top: 16rem;
    margin-bottom: 8rem;
    overflow: hidden;
  }
  &__content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 7rem;
    @include max-container;

    @include respond(tab-sm) {
      grid-template-columns: 1fr;
    }
  }
  .first-col {
    font-weight: 500;
    font-size: 4.5rem;
    line-height: 6rem;
    font-feature-settings: "liga" off;
    color: #ffffff;
  }
}
.collapse-card {
  padding-bottom: 2.5rem;
  margin-bottom: 2.3rem;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
  &__header {
    display: flex;
    align-items: center;
    & > :first-child {
      width: 4rem;
    }
    & > :not(:last-child) {
      margin-right: 2rem;
    }
    & > :last-child {
      margin-left: auto;
    }
    .title {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-feature-settings: "liga" off;
      color: #ffffff;
    }
    .caret {
      justify-self: end;
      transition: all 0.3s ease-out;
      max-width: 2rem;
    }
    &.not-collapsed .caret {
      transform: rotate(180deg);
    }
  }
  &__body {
    padding-top: 3.3rem;
    margin-left: 6rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-feature-settings: "liga" off;
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>
