<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="top">
        <div class="first-column">
          <img src="@/assets/svg/footer-logo.svg" class="logo" alt="logo" />
          <p class="text">Get LawPavilion content to your inbox.</p>
          <form action="" class="mailing">
            <div class="form-group">
              <a
                class="btn btn--inline"
                href="https://joinlists.getresponsepages.com"
                target="_blank"
                >Subscribe</a
              >
            </div>
          </form>
        </div>
        <div class="second-column">
          <ul class="list">
            <li class="list__item list__header">Products</li>
            <li class="list__item">
              <router-link class="list__link" to="/prime" target="_blank">
                <img
                  src="@/assets/svg/footer-prime-logo.svg"
                  alt="prime logo"
                />
                <span>Prime</span>
              </router-link>
            </li>
            <!-- <li class="list__item">
              <a href="" class="list__link">
                <img
                  src="@/assets/svg/footer-court-manager-logo.svg"
                  alt="court manager logo"
                />
                <span>Court Manager</span>
              </a>
            </li> -->
            <li class="list__item">
              <router-link class="list__link" to="/law-rights" target="_blank">
                <img
                  src="@/assets/svg/footer-lr-logo.svg"
                  alt="law rights logo"
                />
                <span>LawRights</span>
              </router-link>
            </li>
            <li class="list__item">
              <a
                href="http://primsol.lawpavilion.com/"
                class="list__link"
                target="_blank"
              >
                <img
                  src="@/assets/svg/footer-primsol-logo.svg"
                  alt="primsol logo"
                />
                <span>Primsol</span>
              </a>
            </li>
            <li class="list__item">
              <a
                href="http://casemanager.lawpavilion.com/"
                class="list__link"
                target="_blank"
              >
                <img
                  src="@/assets/svg/footer-case-manager-logo.svg"
                  alt="case manager logo"
                />
                <span>Case Manager</span>
              </a>
            </li>
          </ul>
          <ul class="list">
            <li class="list__item list__header">Company</li>
            <li class="list__item">
              <router-link class="list__link" to="/about">
                About us
              </router-link>
            </li>
            <li class="list__item">
              <router-link class="list__link" to="/careers">
                Career
              </router-link>
            </li>
            <li class="list__item">
              <a
                href="https://lawpavilion.com/blog/"
                class="list__link"
                target="_blank"
              >
                Blog
              </a>
            </li>
          </ul>
          <ul class="list" v-if="false">
            <li class="list__item list__header">Legal</li>
            <li class="list__item">
              <a href="" class="list__link"> T&Cs </a>
            </li>
            <li class="list__item">
              <a href="" class="list__link"> Privacy Policy </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <div class="footer__socials">
          <a
            href="https://www.linkedin.com/company/lawpavilion/"
            target="_blank"
            class="socials__link"
          >
            <img
              src="@/assets/svg/socials/in.svg"
              alt=""
              class="footer__socials-icon--in"
            />
          </a>
          <a
            href="https://www.facebook.com/LawPavilion/"
            target="_blank"
            class="socials__link"
          >
            <img
              src="@/assets/svg/socials/facebook.svg"
              alt=""
              class="footer__socials-icon--facebook"
            />
          </a>
          <a
            href="https://twitter.com/lawpavilion"
            target="_blank"
            class="socials__link"
          >
            <img
              src="@/assets/svg/socials/twitter.svg"
              alt=""
              class="footer__socials-icon--twitter"
            />
          </a>
          <a
            href="https://www.instagram.com/lawpavilion/ "
            target="_blank"
            class="socials__link"
          >
            <img
              src="@/assets/svg/socials/instagram.svg"
              alt=""
              class="footer__socials-icon--instagram"
            />
          </a>
        </div>
        <p class="footer__copy sm-show">
          © {{ new Date().getFullYear() }}, LawPavilion Business Solution. All
          Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 9rem 0;
  background: $color-text;
  border-top: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    90deg,
    rgba(221, 175, 132, 0.1) 0%,
    rgba(221, 175, 132, 0.6) 50.38%,
    rgba(221, 175, 132, 0.1) 100%
  );
  @include respond(tab-land) {
    padding: 9rem 2rem;
  }
  &__container {
    @include max-container;
  }
  .top,
  .bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    &:not(:last-child) {
      margin-bottom: 4rem;
    }
    @include respond(tab-sm) {
      grid-template-columns: 1fr;
    }
  }
  .first-column {
    .logo {
      margin-bottom: 0.6rem;
    }
    .text {
      max-width: 18ch;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.4rem;
      color: $color-white;
      margin-bottom: 4.8rem;
    }
    .form-group {
      display: flex;
      & > :not(:last-child) {
        margin-right: 1.6rem;
      }
      .form-control {
        background: $color-white;
        border-radius: 6rem;
        padding: 1.8rem 2.8rem;
        font-family: "Roobert", sans-serif;
        height: inherit;
        font-size: 1.6rem;
        line-height: 1.9rem;
        &::placeholder {
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .btn--inline {
        border-radius: 6rem;
        color: $color-white;
      }

      @include respond(phone) {
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
      }
    }
  }
  .second-column {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6rem;
    @include respond(tab-sm) {
      grid-row: 1;
    }
    @include respond(phone) {
      grid-template-columns: 1fr;
    }
    .list {
      list-style: none;
      & > :not(:last-child) {
        margin-bottom: 2.5rem;
      }
      &__item,
      &__header {
        font-size: 14px;
        line-height: 17px;
      }
      &__item.list__header {
        font-weight: 500;
        letter-spacing: 0.06em;
        color: rgba($color-white, 0.5);
        text-transform: uppercase;
      }
      &__item {
        font-weight: 500;
        color: $color-white;
        text-transform: capitalize;
      }
      &__link {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: $color-white;
        & > :not(:last-child) {
          margin-right: 1.4rem;
        }
      }
    }
  }
  a.btn {
    text-decoration: none;
  }

  &__socials {
    display: flex;
    align-items: center;

    @include respond(tab-port) {
      justify-content: flex-start;
    }

    @include respond(phone) {
      padding: 0 3rem;
      margin-bottom: 2.3rem;
    }

    & > * {
      width: 2rem;
      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }

  &__copy {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgba($color-white, 0.5);
    margin-bottom: 2.4rem;

    @include respond(phone) {
      padding: 0 3rem;
      margin-bottom: 4.8rem;
    }
  }
}
</style>
