<template>
  <div class="loader__row" id="loader">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style lang="scss" scoped>
.loader__row {
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 3000;
  position: fixed;
  top: 0;
  background: $color-primary-pale;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}
.loader {
  $border-color-grey-2: #e1e4e8;

  width: 10rem;
  height: 10rem;
  @include respond(tab-sm) {
    width: 6rem;
    height: 6rem;
  }
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: $color-primary;
  border-bottom-color: $border-color-grey-2;
  border-left-color: $color-primary;
  border-right-color: $border-color-grey-2;
  -webkit-animation: loader 1s ease-in-out infinite;
  animation: loader 1s ease-in-out infinite;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
